<template lang="pug">
page-structure-element(
  :element="element"
  @clicked="navigateTo('OverlayPane')"
  :defaultImage="getBackground('globalStyle')"
) {{ isEmbedded ? $t('containerBackground') : $t('websiteOverlay') }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: {
          background: 'transparent',
        },
      };
    },
  };
</script>
